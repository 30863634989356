@import "./branding.scss";

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/image.sass";
@import "~bulma/sass/elements/title.sass";
// Components
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/components/tabs.sass";
// Grid
@import "~bulma/sass/grid/columns.sass";
// Layout
@import "~bulma/sass/layout/footer.sass";
@import "~bulma/sass/layout/hero.sass";
@import "~bulma/sass/layout/section.sass";


.buy-button {
    border: 1px solid $primary;
    background-color: $yellow;
    color: $primary;
}

.elevated {
    display: contents;
}