@import '../../../../branding.scss';

.blue {
    color: $blue;
}

.red {
    color: $red;
}

.description {
    margin-top: 1rem;

    ul {
        padding-left: 1.5rem;
        li {
            display: list-item;
            list-style-type: circle;
        }
    }
}