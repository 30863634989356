@charset "utf-8";

//******************************************
//* Colors
//******************************************
$blue: #3852e2;
$red: #e21212;
$yellow: #FFE900;
$white: #FFFFFF;
$light-gray: #f2f3f2;

//******************************************
//* Dimensions TODO: is 'Dimensions' accurate?
//******************************************
$section-padding: 2rem 2rem;
$section-padding-medium: 2rem 5rem;
$section-padding-large: 2rem 10rem;
