@import "../../branding.scss";


#buy-button {
    margin-top: 5px;
    margin-bottom: 5px;
}

// .product-image-container {
//     width: 100%;
//     justify-content: center;
//     .product-image {
//     }
// }
#product-container {
    flex-direction: column;

    // #product-image {
        
    // }

    .content {
        margin-top: 10px;
    }
    
    .product-details {
        min-height: 210px;
    }
}

.ingredients-category-separator {
    margin: 0.5rem 0 0.5rem 0;
}