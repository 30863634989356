@import "./branding.scss";

#root {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;

    #main-content {
        flex: 1 0 auto;
        position: relative;
        margin-top: $navbar-height;
        
        z-index: 0;
    }
}